// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '1.000.0',
  production: true,
  errMsg: 'Failed to complete. Please try again.',
  partner: false,
  config: {
    "stage": "prod",
    "appName": "INTERNAL",
    "haystackUrl": "https://silo.prod.service.75f.io/",
    "tagsUrl": "https://silo.prod.service.75f.io/v2/sitePointTags",
    "cpmUrl": "https://chiller-plant.prod.service.75f.io/",
    "alertsUrl": "https://alerts.prod.service.75f.io/",
    "pasUrl": `https://portfolio-analytics.prod.service.75f.io`,
    "auth": {
      "caretakerUrl": "https://caretaker.prod.service.75f.io/api/v1",
      "gatekeeperUrl": "https://gatekeeper.75f.io/api/v1"
    }
  }
};
